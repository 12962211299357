
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, Patient } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete } from '@/lib/components';
import VARefraction from '@/custom/menicon/views/encounter/VARefraction.vue';
import PatientScans from '@/custom/menicon/views/encounter/PatientScans.vue';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import MyopiaPrognosisPage from '@/custom/menicon/views/encounter/MyopiaPrognosisPage.vue';
import DifferenceMaps from '@/custom/menicon/views/encounter/DifferenceMaps.vue';
import InstructionModal from '@/custom/menicon/views/encounter/InstructionModal.vue';
import SurveyResultCharts from '@/custom/menicon/SurveyResultCharts.vue';
import { EncounterData } from '@/helpers/encounter.helper';
import { countErrors, validate } from '@/helpers/validators.helper';
import { SavingStatus } from '@/lib/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { Laterality } from '@/custom/menicon/models';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    SurveyResultCharts,
    DifferenceMaps,
    MyopiaPrognosisPage,
    VARefraction,
    PatientScans,
    LensOrdering,
    LensOrderingConfirmation,
    WorkflowLayout,
    BaseButton,
    InstructionModal,
    SmartFormComplete,
    ButtonLink,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyType: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    encounter: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string;
  patientId!: string;
  patient!: Patient;
  encounterData!: EncounterData | null;
  encounter!: Encounter;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  laterality!: Laterality | null;

  get showVirtualDoctor() {
    return isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
  }

  get steps(): string[] {
    return [
      ...(this.showVirtualDoctor ? [this.$t('custom.menicon.virtual-doctor.name') as string] : []),
      '',
      this.$t('custom.menicon.encounter.va-refraction.name') as string,
      '',
      this.$t('custom.menicon.encounter.patient-scans.name') as string,
      this.$t('custom.menicon.encounter.difference-maps.name') as string,
      this.$t('custom.menicon.encounter.slit-lamp.name') as string
    ];
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get smartFormCompleteProps() {
    return {
      heading: this.$t('custom.menicon.prescriptions.follow-up-completed-heading') as string,
      body: this.$t('custom.menicon.prescriptions.follow-up-completed-body') as string
    };
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next');
    }
  }

  complete() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('complete');
    }
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }

  validateCurrentStep() {
    let errors = {};
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.va-refraction.name') as string) &&
      this.rules?.vaRefraction
    ) {
      errors = validate(this.rules.vaRefraction, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.patient-scans.name') as string) &&
      this.rules?.patientScans
    ) {
      errors = validate(this.rules.patientScans, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    this.$emit('setErrors', errors);
  }

  back() {
    this.$emit('back');
  }
}
